// project import
import pages from './pages';
// import dashboard from './dashboard';

// ==============================|| MENU ITEMS ||============================== //

const superadminmenuItems = {
  items: [pages]
};

export default superadminmenuItems;
