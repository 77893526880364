  import { Outlet } from 'react-router-dom';

  // ==============================|| MINIMAL LAYOUT ||============================== //

  const UserMinimalLayout = () => (
    <>
      <Outlet />
    </>
  );

  export default UserMinimalLayout;
