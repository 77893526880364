// project import
import pages from './pages';
import dashboard from './dashboard';

// ==============================|| MENU ITEMS ||============================== //

const superadminmenuItems = {
  items: [dashboard, pages]
};

export default superadminmenuItems;
